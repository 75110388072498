
































import './styles/RolesPopup.scss';
import './styles/RolesPopupAdaptive.scss';
import { ITogglePopupPayload } from '@/interfaces/ITogglePopupPayload';
import { ProjectTeamActions } from '@/store/modules/project-team/Types';
import { IMemberInfo, IMemberRole } from '@store/modules/project-team/interfaces/Interfaces';
import { IProjectTeamPopupsState } from '@store/modules/project-team/modules/popups/Interfaces';
import { PopupActions} from '@store/Popup';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IProjectState } from "@store/modules/project/Interfaces";

const NSProjectTeamPopups = namespace('storeProjectTeam/storeProjectTeamPopups');
const NSProject = namespace('storeProject');
const NSProjectTeam = namespace('storeProjectTeam');

@Component({
    name: 'RolesPopup',

    components: {
        BasePopup: () => import('@components/popups/BasePopup.vue'),
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
    },
})
export default class RolesPopup extends Vue {
    $refs!: {
        basePopup: any;
    }

    chosenRole = {} as IMemberRole;

    @NSProject.Getter('projectId') projectId!: number;

    @NSProject.Getter('memberRoles') roles!: IMemberRole[];

    @NSProjectTeamPopups.Getter('rolesPopupToggler') rolesPopupToggler!: boolean;

    @NSProjectTeamPopups.Action(PopupActions.A_TOGGLE_POPUP)
    togglePopup!: (
        payload: ITogglePopupPayload<IProjectTeamPopupsState>,
    ) => void;

    @NSProjectTeam.Action(ProjectTeamActions.A_APPLY_ROLE_TO_VIEWED_MEMBER)
    applyRoleToViewedMember!: (
        payload: { role: IMemberRole, projectId: number, }
    ) => Promise<void>;

    @Watch('rolesPopupToggler')
    watchRolesPopupToggler() {
        if (this.rolesPopupToggler) this.$refs.basePopup.openPopup();
        else this.$refs.basePopup.closePopup();
    }

    chooseRole(role: IMemberRole) {
        this.chosenRole = role;
    }

    applyRole() {
        this.applyRoleToViewedMember({ role: this.chosenRole, projectId: this.projectId })
            .then(() => {
                this.$emit('popup-confirm-apply-role', this.chosenRole.label);
            });
        this.closePopup();
    }

    closePopup() {
        this.togglePopup({
            popupToggler: 'rolesPopupToggler',
            state: false,
        });
    }
}
